.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.stimulusContainer {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.stimulusText {
  color: #ffffff;
  width: 300px;
}

.stimulusScore {
  width: 50px;
}

.stimulusRequired {
  color: #ffffff;
}

/*  TurfStimuliSelectorModal styles */
.rowSpacedContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  /* width: 100%; */
}

.leftControlsContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.rightPaneContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  flex-grow: 1;
}