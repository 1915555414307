.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ffffff;
  border-radius: 10px; */
  padding: 20px;
  width: 90%;
  margin: 10px 10px 20px 10px;
  color: #ffffff;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.metaStuff {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px 50px 10px 50px;;
  margin: 0px
}

.comboStats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  margin: 5px 0px 5px 0px;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

.stimulusStyle {
  font-size: 20px;
}

.accordion {
  width: 100%;
}

.heading {
  font-size: 18px;
  font-weight: 900;
}