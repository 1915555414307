/* styles.css */

.previewTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  padding: 30px;
  margin: 0;
}

.previewFooter {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.textField {
  margin: 0;
  width: 100%;
  color: #ffffff;
}

.textField .MuiInput-underline:after {
  border-bottom-color: green;
}

.textField .MuiOutlinedInput-root fieldset {
  border-color: white;
}

.textField .MuiOutlinedInput-root:hover fieldset {
  border-color: white;
}

.textField .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: white;
}

.swytchbackInputStyles {
  /* minHeight: 100px; */
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  text-align: left;
  margin-left: 0;
}

.swytchbackMultiLineInputStyles {
  /* minHeight: 100px; */
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  text-align: left;
  margin-left: 8px;
  margin-right: 8px;
}

.swytchbackInputLabelStyles {
  font-size: 18px;
  color: #ffffff;
}

.swytchbackInputLabelStyles::placeholder {
  opacity: 1;
}

.swytchbackMenuItem {
  font-size: 14px;
}

.secondTitle {
  font-size: 14px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.label {
  font-size: 14px;
}

.buttonStyle {
  height: 50px;
  width: 70px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.accordionRoot {
  width: 100%;
  background-color: #dee1e6;
}

.accordionHeading {
  font-size: 16px;
  font-weight: 500;
}

.columnContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
