.title {
  color: #2c3e50;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f7f7f7;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  padding: 20px 1vw 20px 1vw;
}

.buttonContainerStyle {
  padding: 10px 10px 10px 10px;
}

.buttonStyle {
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
  font-size: 14px;
  width: 100%;
}

.paperStyle {
  background-image: linear-gradient(#040303, #595a5a);
  width: 300px;
  padding-bottom: 10px;
}